.App {
  text-align: center;
  min-height:100vh
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input_label {
  color: black !important;
}
.my-button {
  background:#ffd700 !important;
  color:black !important;
}
.my-button:hover {
  background: #c2a500 !important;
}
.footer-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* gap:1rem; */
  align-items: center;
  margin:0 !important;
  padding: 1rem 0rem;
}
.footer-list a img {
  width: 25%;
  object-fit: contain;

}
body {
  min-height:100vh;
}

#root {
  height: 100%;
}

@media (max-width: 768px) {
  .form-container {
    max-width: 85vw !important;
  }
}
@media (min-width: 768px) {
.App {
  height: 100vh;
}
}